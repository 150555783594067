export const MaintenanceOutageMessages = {
  title: 'Onderhoud en storingen',

  none: 'Geen geplande onderhoud of storingen',
  noOutages: 'Geen storingen',
  noUpcomingMaintenance: 'Geen geplande onderhoud',
  outageDescriptionFooter:
    '\n\n' +
    '**Heeft u een Premium of Basic overeenkomst?**\n' +
    '\n' +
    "Wij herstellen alle verbindingen op basis van Service Level, is uw verbinding van dusdanig belang voor uw onderneming dat u ook prioriteit wenst bij het herstellen van de verbinding dan kunt u contact opnemen met <sales{'@'}forefreedom.nl>",
  recentOutages: 'Recente storingen',
  scheduledMaintenance: 'Geplande onderhoud',

  fields: {
    expectedDuration: 'Verwachte downtime',
    location: 'Locatie',
  },

  types: {
    maintenance: 'Onderhoud',
    outage: 'Storing',
  },
};
