import { DealType } from '../../services/hubspot/deal-type.enum.ts';

const dealTypeMessages: { [key in DealType]: string } = {
  after_bundeling: 'Vraagbundeling',
  bundeling: 'Vraagbundeling',
  existing_business: 'Bestaande aansluiting',
  newbusiness: 'Na-aansluiting',
  one_off_sale: 'Losse verkoop',
  renewal: 'Verlenging',
  renewal_downgrade: 'Verlenging',
  renewal_upgrade: 'Verlenging',
  takeover: 'Contractovername',
  termination: 'Opzegging',
  upgrade: 'Upgrade',
  voip: 'VoIP',
};

export const DealMessages = {
  types: dealTypeMessages,
  properties: {
    clientName: 'Klantnaam',
    comment: 'Opmerking',
    connectionAddress: 'Aansluitadres',
    contractTerm: 'Contracttermijn',
    createDate: 'Besteldatum',
    dealStage: 'Status',
    dealType: 'Type',
    deliveryDate: '(Verwachte) Opleverdatum',
    desiredActivationDate: 'Gewenste activeringsdatum',
    installationDate: 'Installatiedatum',
    orderNumber: 'Ordernummer',
    reference: 'Referentie',
    status: 'Status',
  },
};
